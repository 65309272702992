.brand-logo {
    height: 36px;
}

.logo-link {
    margin-right: 12px;
}

.mobile-only {
    display: inline-block;
}

.large-devices-only {
    display: none;
}

.MuiToolbar-root.MuiToolbar-regular {
    justify-content: space-between;
}

@media only screen and (min-width: 900px) {
    .mobile-only {
        display: none;
    }

    .large-devices-only {
        display: inline-block;
    }
}
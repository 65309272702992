.products-container {
    display: flex;
    flex-wrap: wrap;
    padding: 12px;
}

.product-container {
    width: 48%;
    margin: 1%;
    padding: 8px;
    -webkit-box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.75);
}

.product-img img {
    width: 100%;
}

.product-name {

}

.price-container .selling-price {

}

.price-container .mrp-price {
    text-decoration: line-through;
}

.lot-container {
    display: flex;
}

.lot-container span {
    display: block;
}

@media screen and (min-width: 481px) and (max-width: 720px) {
    .product-container {
        width: 32%;
        margin: 0.5%;
    }
}

@media screen and (min-width: 721px) {
    .product-container {
        width: 24%;
        margin: 0.5%;
    }
}
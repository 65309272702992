.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 90px;
}

.logo {
    max-width: 300px;
}

.login-btn:hover {
    background-color: #FFD600;
    opacity: 0.9;
    color: #6200FF;
}
.splash-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: var(--color-blue);
}

.splash-logo {
    height: 150px;
    animation: splash 2s infinite;
}

.splash-brand-name {
    color: #ffffff;
}

@keyframes splash {
    from {height: 150px;}
    to {height: 140px;}
}

.loader {
    margin-top: 24px;
    width: 60px;
    aspect-ratio: 4;
    background: radial-gradient(circle closest-side, #FC02D4 90%, #0000) 0/calc(100%/3) 100% space;
    clip-path: inset(0 100% 0 0);
    animation: loader 1s steps(4) infinite;
  }
  @keyframes loader {to{clip-path: inset(0 -34% 0 0)}}
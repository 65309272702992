.home-container {
    padding: 8px;
}

.category-container {
    width: 66px;
    overflow: hidden;
}

.category-img-container {
    width: 60px;
    height: 60px;
    overflow: hidden;
    border: 3px solid #FFF2B0;
    border-radius: 12px;
}

.category-img-container img {
    width: 60px;
    padding: 4px;
}

.category-container .category-name {
    display: block;
    line-height: 14px;
    font-size: 12px;
}

.categories-stack {
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 8px 16px;
}
.verification-container {
    max-width: 420px;
    margin: auto;
}

.otp-container {
    width: 100%;
    max-width: 360px;
    margin: 0 auto;
    justify-content: space-between;
}

.otp-box {
    width: 42px !important;
    height: 42px;
    border-radius: 50%;
}

.timer {
    font-size: large;
    font-weight: bold;
    letter-spacing: 1px;
}